import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, ThemeProvider} from "@mui/material";
import {getFromLocalStorage} from "../../localStorageZugriff";
import FetchUtil from "../../util/FetchUtil";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import AutodartsManuelScoreDialog from "./AutodartsManuelScoreDialog";
import {isTabletHochformat, isTabletQuerformat} from "../../util/GeraeteArtUtil";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import {CustomFontTheme} from "../CustomFontTheme";

let stompClient = undefined;
export default function AutodartsConnection({toGo, modusId, onSave, onError, onValueChange, showKeyBoard}) {

    const ref = useRef();
    ref.current = {toGo: toGo, modusId: modusId};

    const URL = getFromLocalStorage("dh_url");
    const TOKEN = getFromLocalStorage("dh_token");
    const BOARDTOKEN = getFromLocalStorage("dh_board_token");
    const AUTODARTSBOARDID = getFromLocalStorage("dh_autodarts_boardid");

    const [editScore, setEditScore] = useState(undefined);

    const [message, setMessage] = useState(undefined);

    const [valueGesamt, setValueGesamt] = useState(undefined);
    const [wuerfe, setWuerfe] = useState([]);
    const [dto, setDto] = useState(undefined);

    const [serverReady, setServerReady] = useState(false);

    const [connected, setConnected] = useState(false);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if( valueGesamt === null || valueGesamt === undefined) {
            onValueChange(0);
        } else {
            onValueChange(valueGesamt);
        }
    }, [valueGesamt, onValueChange]);

    const connectStomp = useCallback(() => {
        let index = URL.search("/api");
        let newUrl = URL.substr(0, index + 5) + "websocket";
        stompClient = Stomp.over(function () {
            return new SockJS(newUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
          () => {
              console.log("Websocket - connect success");
          },
          () => {
              console.log("Websocket - connect error:");
              onError("Fehler beim Verbindungsaufbau");
          },
          () => {
              console.log("Websocket - disconnect:");
          });
    }, [URL, onError]);

    useEffect(() => {
        if( serverReady ) {
            connectStomp();
        }
    }, [serverReady, connectStomp]);

    const checkIfServerReady = useCallback(() => {
        FetchUtil.fetchGet(URL + "/autodarts/status", TOKEN, BOARDTOKEN,
          json => {
              setServerReady(json.online);
          },
          responseNotOk => {
              onError("Fehler beim Ermitteln des Status:" + responseNotOk.message)
          },
          error => {
              onError("Fehler beim Ermitteln des Status:" + error.message)
          })
    }, [URL, TOKEN, BOARDTOKEN, onError]);

    const unsubscribeFromAutodarts = useCallback(() => {
        FetchUtil.fetchPost(URL + "/autodarts/unsubscribe/" + AUTODARTSBOARDID, TOKEN, BOARDTOKEN,
          {},
          json => {
              setConnected(false);
          },
          responseNotOk => {
              onError("Fehler beim Lösen von Board:" + responseNotOk.message)
          },
          error => {
              onError("Fehler beim Lösen von Board:" + error.message)
          })
    }, [URL, AUTODARTSBOARDID, TOKEN, BOARDTOKEN, setConnected, onError]);

    useEffect(() => {
        checkIfServerReady();
        return () => {
            if(connected) {
                unsubscribeFromAutodarts();
            }
        };
    }, [connected, checkIfServerReady, unsubscribeFromAutodarts]);

    const sendSave = useCallback((validThrows, valueGesamt, anzWuerfe) => {
        if( !validThrows) {
            onSave(0, 3);
        } else if ( valueGesamt === ref.toGo) {
            onSave(valueGesamt, anzWuerfe);
        } else {
            onSave(valueGesamt, 3);
        }
    }, [onSave]);

    const onMessageReceived = useCallback((payload) => {
        console.log("-----------------------");
        const json = JSON.parse(payload.body);
        setDto(json);
        if( json.action === "THROW_DETECTED") {
            setWuerfe(json.dtos);
            setValueGesamt(json.valueGesamt);
            checkModusAndValueGesamt(json.dtos, json.valueGesamt);
        } else if( json.action === "TAKOUT_FINISHED") {
            let validThrows = checkModusAndValueGesamt(json.dtos, json.valueGesamt);
            sendSave(validThrows, json.valueGesamt, json.dtos.length);
            setWuerfe([]);
            setValueGesamt(undefined);
            setMessage(undefined);
        } else if( json.action === "MANUAL_RESET") {
            setWuerfe([]);
            setValueGesamt(undefined);
        }
        console.log("ENDE -----------------------");
    }, [sendSave]);

    useEffect(() => {
        let ref = undefined;
        if( connected ) {
            let destination = "/topic/autodarts/" + AUTODARTSBOARDID;
            console.log("subscribe to " + destination);
            if( stompClient !== null && stompClient !== undefined) {
                ref = stompClient.subscribe(destination, onMessageReceived);
            }
        }


        return function cleanup() {
            if( ref !== undefined) {
                console.log("unsubscribe")
                ref.unsubscribe();
            }
        }
    }, [connected, AUTODARTSBOARDID, onMessageReceived]);

    const checkModusAndValueGesamt = (dtos, gesamt) => {
        if( dtos.length === 0) {
            return true;
        }
        if( gesamt === ref.current.toGo) {
            let letztesDto = dtos[dtos.length - 1];
            if( ref.current.modusId === "double_out" || ref.current.modusId === "double_in_double_out") {
                let letztesDto = dtos[dtos.length - 1];
                if( letztesDto.multiplier === 1 && letztesDto.value === 50) {
                    return true;
                }
                if( letztesDto.multiplier !== 2) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
            else if( ref.current.modusId === "triple_out") {
                if( letztesDto.multiplier === 1 && letztesDto.value === 50) {
                    return true;
                }
                if( letztesDto.multiplier !== 3) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
            else if( ref.current.modusId === "master_out") {
                if( letztesDto.multiplier === 1 && letztesDto.value === 50) {
                    return true;
                }
                if( letztesDto.multiplier === 1 && letztesDto.value !== 50) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
        } else {
            if( ref.current.modusId === "double_out" || ref.current.modusId === "double_in_double_out" || ref.current.modusId === "master_out") {
                if( ref.current.toGo - gesamt < 2) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
            else if( ref.current.modusId === "triple_out") {
                if( ref.current.toGo - gesamt < 3) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
        }
        return true;
    }

    const subscribeToAutodarts = () => {
        FetchUtil.fetchPost(URL + "/autodarts/subscribe/" + AUTODARTSBOARDID, TOKEN, BOARDTOKEN,
            {},
            json => {
                setConnected(true);
            },
            responseNotOk => {
                onError("Fehler beim Verbinden mit Board:" + responseNotOk.message)
            },
            error => {
                onError("Fehler beim Verbinden mit Board:" + error.message)
            });
    }

    const sendReset = () => {
        let url = "/app/autodarts/reset/" + AUTODARTSBOARDID;
        let json = {};
        console.log("sendReset", json);
        stompClient.send(url, {}, JSON.stringify(json));
    }

    const changeScore = (changeDto) => {
        setMessage(undefined);
        let newWuerfe = undefined;
        let wurf = wuerfe[editScore];
        if( wurf === undefined) {
            // wenn der selektierte score nicht 0 ist, muss der score davor vorhanden sein.
            if( editScore > 0 ) {
                if( wuerfe[editScore -1] === undefined) {
                    onError("Bitte Wurf davor zuerst bearbeiten...");
                    setEditScore(undefined);
                    return;
                }
            }
            newWuerfe = [...wuerfe];
            newWuerfe.push(changeDto);
        } else {
            newWuerfe = [];
            for(let i = 0; i < wuerfe.length; i++) {
                if( i !== editScore) {
                    newWuerfe.push(wuerfe[i]);
                } else {
                    newWuerfe.push(changeDto);
                }
            }
        }
        setWuerfe(newWuerfe)
        let tmp = 0;
        for (const wuerfeElement of newWuerfe) {
            tmp = tmp + wuerfeElement.value;
        }
        setValueGesamt(tmp);
        setEditScore(undefined);
        checkModusAndValueGesamt(newWuerfe, tmp);
        let isChangeValid = checkModusAndValueGesamt(newWuerfe, tmp);
        sendChange(isChangeValid ? tmp : 0, newWuerfe);
    }

    const sendChange = (tmpValue, tmpWuerfe) => {
        let url = "/app/autodarts/change/score/" + AUTODARTSBOARDID;
        let json = {valueGesamt: tmpValue, dtos: tmpWuerfe, changed: true};
        stompClient.send(url, {}, JSON.stringify(json));
    }

    const isGesamtValueBiggerThanToGo = () => {
        return valueGesamt > ref.current.toGo
    }

    const getThrowArea = () => {
        let cols = [];
        for(let i = 0 ; i < 3; i++) {
            let wuerfeElement = wuerfe[i];
            cols.push(<div key={"key_wurf" + i} style={{padding:10, width:"25%",cursor:"pointer"}} onClick={() => setEditScore(i)}>
                {wuerfeElement !== undefined && <>
                    {wuerfeElement.name}<br/>
                    <span style={{fontSize:30}}>{wuerfeElement.value}</span>
                </>
                }
                {wuerfeElement === undefined &&
                    <img src="/images/dart_single.png" style={{width:50}} alt="dart" />
                }
            </div>);
        }
        cols.push(<div key={"key_wuerfe_gesamt"} style={{padding: 10, width: "25%", color: "whitesmoke"}}>
            Gesamt<br/>
            {valueGesamt}
        </div>);
        return <div style={{display: "flex", color:"whitesmoke", textAlign:"center", fontSize:35}}>
            {cols}
        </div>
    }

    const getBoardStatusArea = () => {
        return <div style={{width: "100%", display: "flex", alignItems:"center", borderTop: "1px solid white", padding: 5, color: "white"}}>
            <div style={{width:400, textAlign:"center"}}>{dto !== undefined && dto.event !== undefined && dto.event}</div>
            <div style={{width:200, textAlign:"right"}}>
                <Button variant="contained" onClick={() => sendReset()} style={{marginRight:1}}>Reset</Button>
                <Button variant="contained" onClick={() => {
                    let validThrows = checkModusAndValueGesamt(wuerfe, valueGesamt);
                    sendSave(validThrows, validThrows ? valueGesamt : 0, wuerfe.length);
                    setWuerfe([]);
                    setValueGesamt(undefined)
                }}>Save</Button>
            </div>
        </div>
    }
    const getBottomArea = () => {
        return <div style={{display: "flex", fontSize: 16, color: "whitesmoke", textAlign: "center", alignContent:"center"}}>
            <img src="/images/autodarts_powered_by.png" style={{height:50, marginLeft: 10, marginTop: 5, marginBottom: 5}} alt="Autodarts" />
            <img src="/images/darthelfer_quer_weiss.png" style={{height:50, marginLeft:10, marginRight:10, marginBottom: 5, marginTop: 5}} alt="darthelfer.de" />
        </div>
    }

    const dataArea = () => {
        return <>
            {serverReady && connected && <>
                {(isGesamtValueBiggerThanToGo() || (message !== undefined && message !== "")) &&
                    <div style={{backgroundColor: "#94038c", color:"white", textAlign:"center", fontSize:30, padding:10}}>
                        {isGesamtValueBiggerThanToGo() ? "Überworfen" : message}
                    </div>
                }
                <div style={{backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)", height: 120}}>
                    {getThrowArea()}
                </div>
                <div style={{backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)"}}>
                    {getBoardStatusArea()}
                </div>
            </>
            }
            <div style={{height: 60, backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)", borderTop: "1px solid white"}}>
                {getBottomArea()}
            </div>
        </>
    }

    const connectArea = () => {
        return <>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<img src={serverReady ? "/images/autodarts_green.png" : "/images/autodarts_red.png"}
                                                       style={{width: 40, height: 40, cursor: "pointer"}} onClick={() => ""}
                                                       alt="Autodarts status" />}
                        onClick={() => {
                            if (connected) {
                                unsubscribeFromAutodarts();
                            } else {
                                subscribeToAutodarts();
                            }
                        }}/>
            </ThemeProvider>
            {serverReady &&
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" startIcon={<PublicOutlinedIcon style={{fontSize: 40, color: connected ? "lightgreen" : "white"}}/>}
                            onClick={() => {
                                if (connected) {
                                    unsubscribeFromAutodarts();
                                } else {
                                    subscribeToAutodarts();
                                }
                            }}/>
                </ThemeProvider>
            }
        </>

    }

    if( showKeyBoard ) {
        return "";
    }

    if( isTabletHochformat(dimensions)) {
        return <>
            <div style={{position: "fixed", left: 5, bottom: 80, width: 600}}>
                {connectArea()}
                {dataArea()}
            </div>
            {editScore !== undefined && <AutodartsManuelScoreDialog score={editScore} onClose={() => setEditScore(undefined)}
                                                                    onScore={(changeDto) => changeScore(changeDto)}/>}
        </>
    } else if( isTabletQuerformat(dimensions)) {
        return <>
            <div style={{position: "fixed", right: 5, bottom: 5, width: 600}}>
                {connectArea()}
                {dataArea()}
            </div>
            {editScore !== undefined && <AutodartsManuelScoreDialog score={editScore} onClose={() => setEditScore(undefined)}
                                                                    onScore={(changeDto) => changeScore(changeDto)}/>}
        </>
    } else {
        return <>
            <div style={{position: "fixed", left: 800, bottom: 5, width: 180}}>
                {connectArea()}
            </div>
            <div style={{position: "fixed", left: 180, bottom: 5, width: 600}}>
                {dataArea()}
            </div>

            {editScore !== undefined && <AutodartsManuelScoreDialog score={editScore} onClose={() => setEditScore(undefined)}
                                                                    onScore={(changeDto) => changeScore(changeDto)}/>}
        </>
    }
}
